.row{
    background: #f7f7f7;
    margin: 0 1em;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    min-height: 150px;
    padding: .25em 0;
    justify-content: center;
}

.row:nth-child(even){
    background: white;
}


.row:hover{
    background: #e6e6e6;
}

.row-image{
height: auto;
max-height: 75px;
width: auto;
max-width: 35%;
}

.text{
    font-size: 14px;
    color: #aa9070;
}
